import FilledButton from 'primitives/Buttons/FilledButton';
import ModalTitle from './primitives/ModalTitle';
import P from 'primitives/Typography/BodyCopy/P';
import terms from './terms';
import TermsText from './primitives/TermsText';

type Props = {
  close: () => void;
};

function TermsModal({ close }: Props) {
  const description = terms();
  return (
    <>
      <ModalTitle>Subscription Offer Terms</ModalTitle>
      <P>
        <TermsText>{description}</TermsText>
      </P>
      <FilledButton isBlock marginTop="1rem" onClick={close}>
        Got It
      </FilledButton>
    </>
  );
}

export default TermsModal;
