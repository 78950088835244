import composeRequest, {
  authHeaders,
  body,
  header,
  method,
  urlTagged,
} from 'api/helpers';

export function getRecurlyPurchasePreview({
  ampUrl,
  autoRenew,
  billingToken,
  planCode,
  profileId,
  sessionId,
}) {
  return composeRequest(
    authHeaders(profileId, sessionId),
    urlTagged`${{
      ampUrl,
    }}/api/v3/subscription/external/recurly/purchase/preview`,
    method('post'),
    body({
      autoRenew,
      billingToken,
      planCode,
    }),
    header('Content-Type', 'application/json'),
    header('Accept', 'application/json; charset=utf-8'),
  )();
}

export function sendRecurlyPurchase({
  ampUrl,
  autoRenew,
  billingToken,
  validationToken,
  firstName,
  lastName,
  email,
  planCode,
  profileId,
  sessionId,
}) {
  return composeRequest(
    authHeaders(profileId, sessionId),
    urlTagged`${{ ampUrl }}/api/v3/subscription/external/recurly/purchase`,
    method('post'),
    body({
      autoRenew,
      billingToken,
      validationToken,
      firstName,
      lastName,
      email,
      planCode,
    }),
    header('Content-Type', 'application/json'),
    header('Accept', 'application/json; charset=utf-8'),
  )();
}
