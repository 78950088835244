import SpinnerPath from 'components/LoadingSpinner/SpinnerPath';
import { ReactNode } from 'react';

export type Style = {
  background: string;
  bufferColor: string;
  spinnerFill: string;
  spinnerStroke: string;
  textColor: string;
};

type LoadingSpinnerProps = {
  children?: ReactNode;
  loading: boolean;
  strokeWidth: number;
  style: Style;
};

const LoadingSpinner = ({
  strokeWidth,
  loading,
  children,
  style,
}: LoadingSpinnerProps) => {
  const viewBoxSize = 42;
  const viewBox = `0 0 ${viewBoxSize} ${viewBoxSize}`;

  const x = viewBoxSize / 2;
  const radius = 100 / (Math.PI * 2);
  const diameter = radius * 2;
  const y = (viewBoxSize - diameter) / 2;
  const path = `M${x} ${y} a ${radius} ${radius} 0 0 1 0 ${diameter} a ${radius} ${radius} 0 0 1 0 -${diameter}`;

  return (
    <svg
      data-test="loading-spinner-container"
      style={{ color: style.textColor }}
      viewBox={viewBox}
    >
      <path
        d={path}
        fill={style.bufferColor}
        stroke={style.bufferColor}
        strokeWidth={strokeWidth + 6}
      />
      <path
        d={path}
        fill={style.background}
        stroke={style.background}
        strokeWidth={strokeWidth}
      />
      {loading && (
        <SpinnerPath
          d={path}
          data-test="loading-spinner"
          fill={style.spinnerFill}
          stroke={style.spinnerStroke}
          strokeWidth={strokeWidth}
        />
      )}
      {children}
    </svg>
  );
};

export default LoadingSpinner;
