import getScript from 'utils/getScript';
import reduxFactory from 'state/factory';
import { getRecaptchaKey } from 'state/Config/selectors';

const store = reduxFactory();

export function create() {
  window.__onGrecaptchaReady = function __onGrecaptchaReady() {
    window.grecaptcha.render(document.querySelector('.g-recaptcha'), {
      sitekey: getRecaptchaKey(store.getState()),
      theme: 'light',
    });
  };

  return window.grecaptcha ?
      window.__onGrecaptchaReady()
    : getScript(
        'https://www.google.com/recaptcha/api.js?onload=__onGrecaptchaReady&render=explicit',
      );
}

export const createV3 = () =>
  // TODO: IHRWEB-16371 - This site key was requested to be hardcoded within this file while transitioning to
  // v3 of recaptcha, once fully transitioned over, this can be extracted out of the file.
  getScript(
    'https://www.google.com/recaptcha/api.js?render=6LesRw8aAAAAAGsr2tE_-Gwuf30wq_tehiVfvt2r',
  );
