import H3 from 'primitives/Typography/Headings/H3';

const renderTerms = () => (
  <div>
    <H3>Plus and All Access Services</H3>
    <p>
      iHeartMedia provides various types of music streaming services: (1) the
      &quot;Free-of-Charge Service&quot;; (2) the &quot;Plus Service&quot;; (3)
      the &quot;All Access Service&quot;; and (4) an &quot;All Access Family
      Plan.&quot; The following terms are applicable only if you purchase the
      Plus Service, the All Access Service, or the Family Plan (each, a
      &quot;Paid Subscription Service&quot;). In order to obtain access to the
      Paid Subscription Service, you must also pay a specified, automatically
      recurring subscription fee (e.g., monthly, quarterly, or annually).
      Further details about the Paid Subscription Service can be found at
      iheartradioondemand.com. iHeartMedia may, at its sole discretion,
      prospectively change the features and services offered in any of the Paid
      Subscription Service at any time. The Paid Subscription Services are
      available to US residents only.
    </p>

    <H3>Paid Subscription Service—Billing and Fees</H3>
    <p>
      Before you register for a Paid Subscription Service, iHeartMedia will
      provide notice of and get your affirmative consent to any charges. By
      registering for a Paid Subscription Service (which includes &quot;Direct
      Purchases&quot; and &quot;In-App Purchases,&quot; as defined below), you
      affirmatively consent to pay all fees and charges associated with the Paid
      Subscription Service on a timely basis and are responsible for any charges
      incurred by your account. You warrant that the credit card information
      that you provide is correct and is your account. We are not liable for any
      loss that you may incur as a result of someone else using your password or
      account, whether with or without your knowledge.
    </p>
    <p>
      BY REGISTERING FOR A PAID SUBSCRIPTION SERVICE AND PROVIDING YOUR CREDIT
      CARD INFORMATION, YOU AUTHORIZE US (IN THE CASE OF A DIRECT PURCHASE) OR
      THE APP STORE PROVIDER (IN THE CASE OF AN IN-APP PURCHASE) TO CHARGE YOUR
      CREDIT CARD FOR THE PAID SUBSCRIPTION SERVICE FEE AT THE THEN-CURRENT RATE
      AND ANY ADDITIONAL CHARGES (INCLUDING LATE CHARGES AND APPLICABLE FEDERAL,
      STATE, OR LOCAL TAXES), AS WELL AS TO CHARGE YOUR CREDIT CARD ON AN
      AUTOMATIC AND RECURRING BASIS (E.G., MONTHLY, QUARTERLY, OR ANNUALLY), AS
      CONSENTED TO AT THE TIME OF REGISTRATION, UNTIL YOU CANCEL YOUR PAID
      SUBSCRIPTION SERVICE. You may cancel online by following the instructions
      below (&quot;Paid Subscription Service – Cancellation&quot;).
    </p>
    <p>
      If you make an in-app purchase of the Paid Subscription Service (an
      &quot;In-App Purchase&quot;) via the applicable app store provider (e.g.,
      Apple App Store, Google Play, etc.) (an &quot;App Store Provider&quot;),
      then the App Store Provider will charge your account the applicable fees
      or charges. Billing for In-App Purchases will be handled by the App Store
      Provider, according to their terms.
    </p>
    <p>
      If you purchase a Paid Subscription Service directly from us (a
      &quot;Direct Purchase&quot;), we may use a third party that is not
      affiliated with us to process your payments as merchant of record
      (&quot;Processor&quot;). You agree that this Processor is solely
      responsible for controlling, handling and processing your payments. For
      Direct Purchases, you will receive a bill for the Paid Subscription
      Service fee based on the term of the subscription for which you
      registered. For example, if you signed up for a monthly paid subscription,
      you will be billed one day after each month on the calendar day that
      corresponds with the commencement of your subscription. If your payment
      date falls on a date that does not exist in that month (e.g., the 31st day
      of a month), we will bill your credit card on the last day of the
      applicable month. However, you acknowledge that we reserve the right to
      change our billing methods effective upon notice to you. You may visit
      your member account to update your credit card information, and you agree
      to maintain a valid credit card during the subscription term. You are
      liable for any amounts that are unsettled and overdue. We may deactivate
      your member account without notice if your payment method is invalid and
      your payment becomes past due. Any delinquent payments are due in full
      within thirty (30) days of the termination or cancellation of a Direct
      Purchase Paid Subscription Service.
    </p>
    <H3>Paid Subscription Service—Promotional Trial</H3>
    <p>
      iHeartMedia may offer a free or reduced price trial subscription of the
      Plus Service or the All Access Service for a specified trial period either
      without payment or with payment of a reduced fee (a &quot;Trial&quot;). To
      initiate the Promotional Trial, you will be required to provide your
      credit card information. By initiating the Promotional Trial, you
      affirmatively consent to have your credit card charged automatically on a
      periodically recurring basis if you do not cancel your subscription before
      the end of the Promotional Trial.
    </p>

    <p>
      Unless otherwise specified, Trials are limited to a single Paid
      Subscription Service; you may not obtain more than a single Trial using
      the same account or credit card and if you have previously used the same
      account or credit card to obtain a Trial or Paid Subscription Service,
      then that card will immediately be charged applicable fee upon
      registration. iHeartMedia may, at its sole discretion and to the fullest
      extent permitted by law, revoke or alter a Promotional Trial without
      notice to you and at any time. Your Promotional Trial will end as soon as
      you purchase a Paid Subscription Service.
    </p>

    <p>
      BY INITIATING A PROMOTIONAL TRIAL, YOU AFFIRMATIVELY CONSENT TO BE
      AUTOMATICALLY ENROLLED IN THE PAID SUBSCRIPTION SERVICE YOU SELECTED
      DURING REGISTRATION, AND TO HAVE YOUR CREDIT CARD AUTOMATICALLY CHARGED
      THE APPLICABLE FEES (INCLUDING APPLICABLE FEDERAL, STATE, OR LOCAL TAXES)
      ON A RECURRING PERIODIC BASIS (E.G., MONTHLY, QUARTERLY, OR ANNUALLY),
      UNLESS YOU CANCEL THE PAID SUBSCRIPTION SERVICE PRIOR TO EXPIRATION OF THE
      PROMOTIONAL TRIAL. You may cancel online by following the instructions
      below (&quot;Paid Subscription Service – Cancellation&quot;).
    </p>

    <H3>Paid Subscription Service—All Access Family Plan</H3>
    <p>
      If you purchase an All Access Family Plan, you, as the paying account
      holder may designate up to five (5) non-paying account holders (each, a
      &quot;Family Account Holder&quot;) to access the All Access Service. You
      will be the only party responsible for paying for the All Access Family
      Plan and may add or remove Family Account Holders from your All Access
      Family Plan at any time. To invite a prospective Family Account Holder to
      participate in your All Access Family Plan, you must provide the
      prospective Family Account Holder’s email address. iHeartMedia will send
      an email to the prospective Family Account Holder inviting them to
      participate in your All Access Family Plan. To accept your invitation to
      participate in the All Access Family Plan, the Family Account Holder must
      create an account with iHeartMedia (if they do not already have one) and
      enter the paying account holder’s billing zip code. All Family Account
      Holders must live in the same household and be at least 13 years of age.
    </p>
    <H3>Paid Subscription Service—Automatic Renewal</H3>
    <p>
      By registering for a Paid Subscription Service, you affirmatively consent
      to have your Paid Subscription Service automatically renewed, and to have
      your credit card charged on a periodically recurring basis (e.g., monthly,
      quarterly, or annually), unless you cancel your subscription before the
      end of your current subscription term. You may cancel online by following
      the instructions below (&quot;Paid Subscription Service –
      Cancellation&quot;). Cancellation will be effective the day after the end
      of your current subscription term. Your failure to cancel your
      subscription during the current term will result in the automatic renewal
      of your subscription at the then-current subscription rate and for the
      same term as your current subscription (e.g., monthly, quarterly, yearly).
      As described below, if iHeartMedia plans to change the subscription rate,
      we will provide you with advance notice of the new rate. The cancellation
      of a Paid Subscription Service will result in a downgrade of your
      subscription service to the Free-of-Charge Service as of the end of your
      current subscription term.
    </p>

    <H3>Paid Subscription Service—Subscription Rate Changes</H3>
    <p>
      We may change the subscription rate from time to time. If we do, we will
      provide you advance notice of any change in the subscription rate. If you
      do not agree with the new subscription rate, you may cancel your
      subscription prior to the new subscription rate taking effect. You may
      cancel online by following the instructions below (&quot;Paid Subscription
      Service – Cancellation&quot;). If you continue to use the Paid
      Subscription Service after the subscription rate has gone into effect, you
      will be charged the new rate until you cancel or the rate changes.
    </p>

    <H3>Paid Subscription Service—Data</H3>
    <p>
      As part of your purchase of a Paid Subscription Service, data collected
      through the registration and payment process may be shared with our
      applicable payment service provider. If you purchase the Paid Subscription
      Service online, such information will be shared with Processor. If you
      make an in-app purchase of the Paid Subscription Service, such information
      will be shared with the applicable app store provider (e.g., Apple App
      Store, Google Play, etc.). Your data will be handled in accordance with
      our Privacy Policy.
    </p>

    <H3>Paid Subscription Service—Member Account and Password</H3>
    <p>
      You are responsible for any membership name and password that is
      associated with your account during registration. If the Paid Subscription
      Service does not recognize your device from a previous sign-in, you will
      be asked for information that will help us to identify your registration.
      It is your responsibility to maintain the confidentiality of your
      password, if one is established. You are entirely responsible for any and
      all activities that occur under your account, and agree to notify us
      immediately of any unauthorized use of your account.
    </p>

    <H3>
      Paid Subscription Service—Limited to US Residents, Personal and
      Non-Commercial Use
    </H3>
    <p>
      The Paid Subscription Services are for US residents only. The Paid
      Subscription Services are for your personal and non-commercial use only.
      You may not modify, copy, distribute, transmit, display, perform,
      reproduce, publish, license, create derivative works from, transfer, or
      otherwise exploit the Paid Subscription Services without our prior written
      consent.
    </p>

    <H3>Paid Subscription Service—Cancellation</H3>
    <p>
      You can cancel your Direct Purchases or a Paid Subscription Service at any
      time by going to your online account &quot;Settings&quot; and following
      the cancellation instructions; you may cancel your In-App Purchases by
      following your App Store Provider’s instructions. In the event that you
      cancel a Paid Subscription Service before the end of your subscription
      period, we will not return any portion of your subscription fee provided
      and you will be entitled to continue accessing the applicable Paid
      Subscription Service until the end of your membership period.
    </p>
    <p>
      If you violate this Agreement, we reserve the right to terminate your
      access to a Paid Subscription Service or any portion thereof at any time,
      without notice.
    </p>
  </div>
);

export default renderTerms;
