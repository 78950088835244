import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const progressAnimation = keyframes`
  0% {
    stroke-dasharray: 1 99;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 35 65;
  }
  100% {
    stroke-dasharray: 1 99;
    stroke-dashoffset: -100;
  }
`;

const SpinnerPath = styled('path')({
  animation: `${progressAnimation} 1s linear infinite`,
  strokeLinecap: 'round',
});

export default SpinnerPath;
