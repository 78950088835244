import styled from '@emotion/styled';

const TermsText = styled('div')({
  h3: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    marginBottom: '1.4rem',
    textAlign: 'center',
  },
  height: '30rem',
  overflowX: 'scroll',
  p: {
    fontSize: '1.2rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },

  paddingTop: '2.2rem',
});

export default TermsText;
